<template>
  <v-container fluid fill-height class="p-5">
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 md4 class="px-5">
        <div style="font-size: 24pt" class="pt-5">Akses Masuk</div>
        <div
          class="lead pb-5"
          style="color: #9e9fb4 !important; font-size: 14pt"
        >
          Akses masuk ke akun anda menggunakan kode akun dab password anda yang
          masih aktif.
        </div>
        <v-form>
          <v-text-field
            v-model="data.kodeAkun"
            label="Kode Akun"
            name="kodeAkun"
            prepend-inner-icon="mdi-account"
            outlined
            rounded
            maxlength="16"
            :disabled="loading"
            :rules="[rules.required, rules.kodeAkunMatch]"
          />
          <v-text-field
            id="password"
            v-model="data.password"
            label="Password"
            name="password"
            outlined
            prepend-inner-icon="mdi-lock"
            rounded
            :disabled="loading"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
        <v-btn
          :loading="loading"
          :disabled="!isComplate"
          color="teal darken-1"
          class="pl-5 pr-5"
          rounded
          x-large
          :dark="isComplate"
          block
          @click="postLogin"
        >
          Masuk
        </v-btn>
        <v-snackbar v-model="snackbar">
          {{ error }}
          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from '@/plugins/supports'

export default {
  data () {
    return {
      data: {
        kodeAkun: '',
        password: '',
        remember_me: false
      },
      showPassword: false,
      rules: {
        required: (value) => {
          return !!value || 'Tidak Boleh Kosong'
        },
        kodeAkunMatch: (v) => {
          return v.length === 16 || 'Kode Akun Tidak Valid'
        }
      },
      loading: false,
      snackbar: false,
      error: {}
      // isComplate: false
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    isComplate () {
      return (
        !isEmpty(this.data.kodeAkun) &&
        !isEmpty(this.data.password) &&
        !this.loading
      )
    }
  },
  created () {
    // You can get url_string from window.location.href if you want to work with
    // the URL of the current page

    if (this.isAuth) {
      this.$router.push({ name: 'home' })
    }
  },
  methods: {
    ...mapActions(['login']),
    postLogin () {
      this.loading = true
      this.login(this.data)
        .then((response) => {
          this.loading = false
          this.$emit('setup-menu')
          this.$router.push({ name: 'Home' })
        })
        .catch((error) => {
          if (error.status == null) {
            console.log('error bro => ', error.message)
            this.error =
              'Sepertinya ada masalah jaringan, mohon periksa jaringan anda'
          }
          // kalau jaringan ga ada
          // kalau internal server error
          if (error.response) {
            console.log('error.response => ', error)
            this.error = error
          }
          // kalau respon gagal login
          if (error.data) {
            console.log('error.data => ', error.data)
            this.error = error.data.msg
          }
          setTimeout(
            () => {
              this.loading = false
              this.snackbar = true
            },
            error.status == null ? 1500 : 100
          )
        })
    }
  }
}
</script>
